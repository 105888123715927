<!--
 * @Description: 我的购物车页面组件
 * @Author: hai-27
 * @Date: 2020-02-20 01:55:47
 * @LastEditors: hai-27
 * @LastEditTime: 2020-02-27 13:36:42
 -->

<template>
	<div class="shoppingCart">
		<!-- 购物车头部 -->
		<div class="cart-header">
			<div class="cart-header-content">
				<p>
					<!-- <i class="el-icon-shopping-cart-full" style="color:#ff6700; font-weight: 600;"></i> -->
					购物车
				</p>
				<!-- <span>温馨提示：产品是否购买成功，以最终下单为准哦，请尽快结算</span> -->
			</div>
		</div>
		<!-- 购物车头部END -->
		<!-- <button @click="gwc = !gwc">gwctext</button>
		<div v-show="gwc"> -->
		<!-- 购物车主要内容区 -->
		<div class="content" v-if="getShoppingCart.length>0">
			<p class="AllshopLength">全部商品数<span>（{{getCheckNum}}）</span></p>
			<ul>
				<!-- 购物车表头 -->
				<li class="header">
					<div class="pro-check">
						<el-checkbox v-model="isAllCheck">全选</el-checkbox>
					</div>
					<div class="pro-img"></div>
					<div class="pro-name">商品名称</div>
					<div class="pro-price">单价</div>
					<div class="pro-num">数量</div>
					<div class="pro-total">小计</div>
					<div class="pro-action">操作</div>

				</li>
				<!-- 购物车表头END -->

				<!-- 购物车列表 -->
				<li class="product-list" v-for="(item,index) in getShoppingCart" :key="item.id" style="overflow: hidden;">
					<!-- <div v-for="(item,index) in items.list" :key="item.id" style="overflow: hidden;"> -->
						<!-- <div class="gongsi">
							某某公司
						</div> -->
						<div class="prolist">
							<div class="pro-check">
								<el-checkbox :value="item.check" @change="checkChange($event,index)"></el-checkbox>
							</div>
							<div class="pro-img">
								<router-link :to="{ path: '/goods/details', query: {productID:item.SkuId} }">
									<img :src="getUrl(item.GoodsUrl)" />
								</router-link>
							</div>
							<div class="pro-name">
								<router-link :to="{ path: '/goods/details', query: {productID:item.SkuId} }">
									{{ item.GoodsName +'  '+ item.SkuName}}
								</router-link>
							</div>
							<div class="pro-price">{{item.Price}}元</div>
							<div class="pro-num">
								<el-input-number size="small" v-model="item.Num"
									@change="handleChange($event,index,item)" :min="1" :max="item.Stock">
								</el-input-number>
							</div>
							<div class="pro-total pro-total-in">{{item.Price*item.Num}}元</div>
							<div class="pro-action" @click="deleteItem($event,item.Id)">
								删除
								<!-- <el-popover placement="right">
									<p>确定删除吗？</p>
									<div style="text-align: right; margin: 10px 0 0">
										<el-button type="primary" size="mini"
											>确定</el-button>
									</div>
									<i class="el-icon-error" slot="reference" style="font-size: 18px;"></i>
								</el-popover> -->
							</div>
						</div>
					<!-- </div> -->

				</li>
				<!-- 购物车列表END -->
			</ul>
			<!-- <div style="height:20px;background-color: #f5f5f5"></div> -->
			<!-- 购物车底部导航条 -->
			<div class="cart-bar">
				<div class="cart-bar-left">
					<span>
						<router-link to="/goods">继续购物</router-link>
					</span>
					<span class="sep">|</span>
					<span class="cart-total selectDelete" @click="SelectDeleteItem">删除选中的商品</span>
				</div>
				<div class="cart-bar-right">
					<span class="cart-total">已选择
						<span class="cart-total-num">{{getCheckNum}}</span> 件
					</span>
					<span>
						<span class="total-price-title">总计：</span>
						<span class="total-price">{{getTotalPrice}}元</span>
					</span>
					<router-link :to="getCheckNum > 0 ? '/confirmOrder?'+'type=1' : ''">
						<div :class="getCheckNum > 0 ? 'btn-primary' : 'btn-primary-disabled'">去结算</div>
					</router-link>
				</div>
			</div>
			<!-- 购物车底部导航条END -->
		</div>
		<!-- 购物车主要内容区END -->

		<!-- 购物车为空的时候显示的内容 -->
		<div v-else class="cart-empty">
			<div class="empty">
				<h2>您的购物车还是空的！</h2>
				<p>快去购物吧！</p>
			</div>
		</div>
		<!-- </div> -->
		<!-- 购物车为空的时候显示的内容END -->

		<!-- 猜你喜欢 -->
		<div class="content GuessLike" style="margin-top: 24px;width: 1240px;margin: 24px auto;">
		  <div class="title">
		    <p>猜你喜欢</p>
		  </div>
		  <div class="GuessList">
		    <el-carousel trigger="click" height="311px" >
		      <el-carousel-item v-for="(item, index) in likeLeng" :key="item">
		        <div class="listbox">
		          <div v-for="(item2, index2) in like[index]" :key="index2"  class="product" >
					  <router-link :to="{ path: '/goods/details', query: { productID: item2.Id } }" class="product" style="border:none;width:100%;height:100%;display:block" >
		            <img :src="getUrl(item2.ImgUrl)" alt="" class="img">
		            <p class="productName">{{ item2.Name }}</p>
		            <p class="productPrice">￥{{ item2.Price }}</p>
				   </router-link>
		          </div>
		        </div>
		      </el-carousel-item>
		    </el-carousel>
		  </div>
		</div>
	</div>
</template>

<script>
	import {
		mapActions
	} from "vuex";
	import {
		mapGetters
	} from "vuex";

	export default {
		inject: ['reload'],
		data() {
			return {
				gwc: false,
				GuessIndex: 0,
				
				like:[],
				likeLeng: 0
			};
		},
		watch: {
			$route(to, from) {

				if (to.name == 'ShoppingCart') {
					// this.reload()
					window.location.reload(); //监测到路由发生跳转时刷新一次页面
				}

			},
		},
		created() {
			this.Like()
			
		},
		methods: {
			async Like(){
				const res = await this.postdata("/Goods/GoodsList", {
			    "page": 1,
			    "limit": 20,
			    "IsStock": 1, //有货商品 1-是
			    "orderBy": 0 //排序 0-综合 1-销量倒序 2-价格正序 3-价格倒序
			  });
			  if (res.code == 200) {
			    let list = res.data.items;
			    if (list.length > 4) {
			      this.likeLeng = parseInt(list.length / 4);
			      if (list.length % 4 == 0)
			        this.likeLeng = list.length / 4;
			      if (list.length % 4 != 0)
			        this.likeLeng =
			          parseInt(list.length / 4) + 1;
			      this.like = this.sliceArray(list, 4);
			    } else {
			      this.likeLeng = 1
			      this.like = list
			    }
			  }
			},
			sliceArray(array, size) {
			  var result = [];
			  for (var x = 0; x < Math.ceil(array.length / size); x++) {
			    var start = x * size;
			    var end = start + size;
			    result.push(array.slice(start, end));
			  }
			  return result;
			},
			newvuex() {
				window.location.reload()
			},
			...mapActions(["updateShoppingCart", "deleteShoppingCart", "checkAll"]),
			// 修改商品数量的时候调用该函数
			async handleChange(currentValue, key, item) {
				console.log(currentValue, key, item)
				// 当修改数量时，默认勾选
				this.updateShoppingCart({
					key: key,
					prop: "check",
					val: true
				});
				// 向后端发起更新购物车的数据库信息请求
				const res = await this.postdata('/Goods/CartOperation', item)
				if (res.code == 200) {
					// 更新vuex状态
					this.updateShoppingCart({
						key: key,
						prop: "Num",
						val: currentValue
					});
					// 提示更新成功信息
					this.notifySucceed(res.msg);
				} else {
					// 提示更新失败信息
					this.notifyError(res.msg);
				}
			},
			checkChange(val, key) {
				// 更新vuex中购物车商品是否勾选的状态
				this.updateShoppingCart({
					key: key,
					prop: "check",
					val: val
				});
			},
			// 向后端发起删除购物车的数据库信息请求
			async deleteItem(e, productID) {
				const res = await this.postdata('/Goods/DelCart', {
					ids: [productID]
				})
				if (res.code == 200) {
					// 更新vuex状态
					this.deleteShoppingCart(productID);
					// 提示删除成功信息
					this.notifySucceed(res.msg);
				} else {
					// 提示删除失败信息
					this.notifyError(res.msg);
				}
			},
			async SelectDeleteItem() {
				var productID = []
				this.getShoppingCart.map(item => {
					if (item.check) {
						productID.push(item.Id)
					}
				})
				const res = await this.postdata('/Goods/DelCart', {
					ids: productID
				})
				if (res.code == 200) {
					// 更新vuex状态
					this.deleteShoppingCart(productID);
					// 提示删除成功信息
					this.notifySucceed(res.msg);
				} else {
					// 提示删除失败信息
					this.notifyError(res.msg);
				}
			}
		},

		computed: {
			...mapGetters([
				"getShoppingCart",
				"getCheckNum",
				"getTotalPrice",
				"getNum"
			]),
			isAllCheck: {
				get() {
					return this.$store.getters.getIsAllCheck;
				},
				set(val) {
					this.checkAll(val);
				}
			}
			
		}
	};
</script>


<style lang="less">
	.el-main {
		.shoppingCart {
			.content {
				.AllshopLength {
					font-size: 16px;
					margin-bottom: 12px;

					span {
						color: #e23435;
					}
				}

				.AllshopLength:hover {
					color: #e23435;
				}

				ul {
					.header {

						.pro-check {
							float: left;
							height: 40px;
							line-height: 40px;
							width: 110px;

							.el-checkbox {
								font-size: 16px;
								margin-left: 24px;
								height: 40px;
								line-height: 40px;
							}

						}

						.pro-img {
							float: left;
							height: 40px;
							line-height: 40px;
							width: 120px;

							img {
								height: 80px;
								width: 80px;
							}
						}

						.pro-name {
							float: left;
							width: 380px;

							height: 40px;
							line-height: 40px;

							a {
								color: #424242;
							}

							a:hover {
								color: #ff6700;
							}
						}

						.pro-price {
							height: 40px;
							line-height: 40px;
							float: left;
							width: 140px;
							padding-right: 18px;
							text-align: center;
						}

						.pro-num {
							height: 40px;
							line-height: 40px;
							float: left;
							width: 150px;
							text-align: center;
						}

						.pro-total {
							height: 40px;
							line-height: 40px;
							float: left;
							width: 120px;
							padding-right: 81px;
							text-align: right;
						}

						.pro-total-in {
							color: #ff6700;
						}

						.pro-action {
							height: 40px;
							line-height: 40px;
							cursor: pointer;
							float: left;
							width: 80px;
							text-align: center;

							i:hover {
								color: #ff6700;
							}
						}



					}

					.el-checkbox__input.is-checked+.el-checkbox__label {
						color: #424242 !important;
					}

					.el-checkbox__input.is-checked .el-checkbox__inner,
					.el-checkbox__input.is-indeterminate .el-checkbox__inner {
						background-color: #fff;
						border-color: #d4d4d4;
					}

					.el-checkbox__input.is-checked .el-checkbox__inner::after {
						border-color: #e23435;
					}

					.el-checkbox__inner:hover {
						border-color: #d4d4d4;
					}

					.el-checkbox__input.is-focus .el-checkbox__inner {
						border-color: #d4d4d4;
					}
				}

			}


			//猜你喜欢
			.GuessLike {
			  margin: 24px auto 0 auto !important;
			
			  .title {
			    font-size: 20px;
			    line-height: 32px;
			    margin-bottom: 12px;
			    font-weight: 600;
			  }
			
			  .GuessList {
			    .listbox {
			      display: flex;
			      margin: 1px;
			
			      .product {
			        margin: -1px 0 0 -1px;
			        border: 1px dashed #cacaca;
			        overflow: hidden;
			        text-align: center;
					padding-bottom: 16px;
			      }
			
			      .img {
			        margin: 12px 0 0 0;
			        width: 169px;
			        height: 169px;
			      }
			
			      .productName {
			        font-size: 18px;
			        line-height: 36px;
					margin-top: 10px;
			        color: #333;
			        display: -webkit-box;
			        -webkit-box-orient: vertical;
			        -webkit-line-clamp: 1; //2行
			        overflow: hidden;
			        word-break: break-all;
			        padding: 0 20px;
			      }
			
			      .productPrice {
			        font-size: 18px;
			        color: #E83F00;
			      }
			
			      .product {
			        width: 25%;
			      }
			
			      .gwcbtn {
			        margin: 16px 0 16px 0;
			        display: inline-block;
			        height: 28px;
			        line-height: 28px;
			        padding: 0 20px;
			        border: 1px solid #999;
			        color: #666;
			        cursor: pointer;
			        text-align: center;
			        -webkit-border-radius: 3px;
			        -moz-border-radius: 3px;
			        border-radius: 3px;
			      }
			    }
			  }
			}
		}
	}
</style>
<style scoped>
	.shoppingCart {
		padding-bottom: 20px;
	}

	/* 购物车头部CSS */
	.shoppingCart .cart-header {
		height: 49px;
		border-bottom: 1px solid #967D67;
		background-color: #fff;
		margin-bottom: 30px;
	}

	.shoppingCart .cart-header .cart-header-content {
		width: 1440px;
		margin: 0 auto;
	}

	.shoppingCart .cart-header p {
		font-size: 24px;
		line-height: 49px;
		float: left;
		font-weight: normal;
		color: #603C1D;
	}

	.shoppingCart .cart-header span {
		color: #757575;
		font-size: 12px;
		float: left;
		height: 20px;
		line-height: 20px;
		margin-top: 30px;
		margin-left: 15px;
	}

	/* 购物车头部CSS END */

	/* 购物车主要内容区CSS */
	.shoppingCart .content {
		width: 1440px;
		margin: 0 auto;
		background-color: #fff;
	}


	.shoppingCart .content ul {
		background-color: #fff;
		color: #424242;

	}

	/* 购物车表头及CSS */
	.shoppingCart .content ul .header {
		height: 40px;
		padding-right: 26px;
		color: #424242;
		background-color: #f3f3f3;
	}

	.shoppingCart .content ul .product-list {
		height: auto;
		padding: 15px 24px 15px 0;
		border: 1px solid #f1f1f1;
		border-top: 2px solid #aaaaaa;
		margin-bottom: 12px;
	}

	.shoppingCart .content ul .pro-check {
		float: left;
		height: 85px;
		width: 110px;
	}

	.shoppingCart .content ul .pro-check .el-checkbox {
		font-size: 16px;
		margin-left: 24px;
	}

	.shoppingCart .content ul .pro-img {
		float: left;
		height: 85px;
		width: 120px;
	}

	.shoppingCart .content ul .pro-img img {
		height: 80px;
		width: 80px;
	}

	.shoppingCart .content ul .pro-name {
		float: left;
		width: 380px;
	}

	.shoppingCart .content ul .pro-name a {
		color: #424242;
		font-size: 18px;
	}

	.shoppingCart .content ul .pro-name a:hover {
		color: #603C1D;
	}

	.shoppingCart .content ul .pro-price {
		float: left;
		width: 140px;
		padding-right: 18px;
		text-align: center;
		line-height: 32px;
	}

	.shoppingCart .content ul .pro-num {
		float: left;
		width: 150px;
		text-align: center;
	}

	.shoppingCart .content ul .pro-total {
		float: left;
		width: 120px;
		padding-right: 81px;
		text-align: right;
	}

	.shoppingCart .content ul .pro-total-in {
		color: #000;
		font-weight: 600;
		line-height: 32px;
	}

	.shoppingCart .content ul .pro-action {
		cursor: pointer;
		float: left;
		width: 80px;
		text-align: center;
		line-height: 32px;
	}

	.shoppingCart .content ul .pro-action i:hover {
		color: #ff6700;
	}

	/* 购物车表头及CSS END */

	/* 购物车底部导航条CSS */
	.shoppingCart .cart-bar {
		width: 1440px;
		height: 50px;
		line-height: 50px;
		background-color: #fff;
		border: 1px solid #f1f1f1;
	}

	.shoppingCart .cart-bar .cart-bar-left {
		float: left;
	}

	.shoppingCart .cart-bar .cart-bar-left a {
		line-height: 50px;
		margin-left: 32px;
		color: #757575;
	}

	.shoppingCart .cart-bar .cart-bar-left a:hover {
		color: #e23435;
	}

	.shoppingCart .cart-bar .cart-bar-left .sep {
		color: #eee;
		margin: 0 20px;
	}

    .shoppingCart .cart-bar .selectDelete{
		cursor: pointer;
		color: #757575;
	}
	.shoppingCart .cart-bar .selectDelete:hover{
		color: #e23435;
	}
	.shoppingCart .cart-bar .cart-bar-right .cart-total {
		color: #757575;
		margin-right: 24px;
	}

	.shoppingCart .cart-bar .cart-bar-right .cart-total-num {
		color: #e23435;
	}

	.shoppingCart .cart-bar .cart-bar-right {
		float: right;
	}

	.shoppingCart .cart-bar .cart-bar-right .total-price-title {
		color: #757575;
		font-size: 16px;
	}

	.shoppingCart .cart-bar .cart-bar-right .total-price {
		color: #e23435;
		font-size: 16px;
		font-weight: 600;
	}

	.shoppingCart .cart-bar .cart-bar-right .btn-primary {
		float: right;
		width: 110px;
		text-align: center;
		font-size: 18px;
		margin-left: 50px;
		background: #e23435;
		color: #fff;
		font-weight: 600;
	}

	.shoppingCart .cart-bar .cart-bar-right .btn-primary-disabled {
		float: right;
		width: 110px;
		text-align: center;
		font-size: 18px;
		margin-left: 50px;
		background: #e0e0e0;
		color: #b0b0b0;
		font-weight: 600;
	}

	.shoppingCart .cart-bar .cart-bar-right .btn-primary:hover {
		background-color: #e23435;
	}

	/* 购物车底部导航条CSS END */
	/* 购物车主要内容区CSS END */

	/* 购物车为空的时候显示的内容CSS */
	.shoppingCart .cart-empty {
		width: 1440px;
		margin: 0 auto;
	}

	.shoppingCart .cart-empty .empty {
		height: 300px;
		padding: 0 0 130px 558px;
		margin: 65px 0 0;
		background: url(../assets/imgs/cart-empty.png) no-repeat 124px 0;
		color: #b0b0b0;
		overflow: hidden;
	}

	.shoppingCart .cart-empty .empty h2 {
		margin: 70px 0 15px;
		font-size: 36px;
	}

	.shoppingCart .cart-empty .empty p {
		margin: 0 0 20px;
		font-size: 20px;
	}

	/* 购物车为空的时候显示的内容CSS END */
</style>
